@import "variables";
@import "frappe/public/css/font-awesome";
@import "~bootstrap/scss/bootstrap";
@import "multilevel-dropdown";
@import "website-image";

html {
  height: 100%;
}

body {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	font-size: 16px;

	> div {
		flex: 1 0 auto;
	}
}

footer {
	flex-shrink: 0;
}

.navbar.bg-dark {
	.dropdown-menu {
		font-size: .75rem;
		background-color: $dark;
		border-radius: 0;
	}

	.nav-link {
		white-space: nowrap;
		color: $light;

		&:hover {
			color: $primary;
		}
	}

	.nav-item {
		padding: 0rem 1rem;
	}

	.dropdown-item {
		color: $light;

		&:hover {
			background-color: $dark;
			color: $primary;
		}
	}
}


.input-dark {
	background-color: $dark;
	border-color: darken($primary, 40%);
	color: $light;
}

.breadcrumb {
    padding-left: 0;
    padding-right: 0;
    background-color: white;
}

a.card {
	text-decoration: none;
}

img {
	max-width: 100%;
}

.hidden {
	@extend .d-none;
}

.hide-control {
    @extend .d-none;
}

.text-underline {
	text-decoration: underline;
}

.text-extra-muted {
	color: #d1d8dd !important;
}

.web-footer {
	padding: 5rem 0;
	min-height: 140px;
	border-top: 1px solid $border-color;
}

.footer-group-label {
	color: $text-muted;
}

.footer-parent-item {
	margin-bottom: 1rem;
}

.no-underline {
    text-decoration: none !important;
}

.indicator {
    font-size: inherit;
}

h4.modal-title {
  font-size: 1em;
}

h5.modal-title {
  margin: 0px !important;
}

.col-xs-1 { @extend .col-1; }
.col-xs-2 { @extend .col-2; }
.col-xs-3 { @extend .col-3; }
.col-xs-4 { @extend .col-4; }
.col-xs-5 { @extend .col-5; }
.col-xs-6 { @extend .col-6; }
.col-xs-7 { @extend .col-7; }
.col-xs-8 { @extend .col-8; }
.col-xs-9 { @extend .col-9; }
.col-xs-10 { @extend .col-10; }
.col-xs-11 { @extend .col-11; }
.col-xs-12 { @extend .col-12; }

.btn-default { @extend .btn-light; }

.btn-xs { @extend .btn-sm; }

.hidden-xs {
	@extend .d-none;
	@extend .d-sm-block;
}

.visible-xs {
	@extend .d-block;
	@extend .d-sm-none;
}

.form-section {
	margin-right: -45px;
}

.form-section .section-body {
	width: 100%;
	padding: 15px;
	@extend .row;
}

.pull-right {
	float: right;
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	vertical-align: middle;
}

.logged-in > .nav-link {
	max-width: 200px;
	@extend .ellipsis;
}